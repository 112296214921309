<template>
  <div id="rate-data" />
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    data() {
      const option = {
        title: {
          text: '订单占比',
          padding: [15, 0, 10, 10],
          textStyle: {
            fontFamily: 'Microsoft YaHei',
            fontSize: 15,
            color: '#464646'
          }
        },
        color: ['#91cc75', '#fac858'],
        legend: {
          top: 10
        },
        tooltip: {
          trigger: 'item'
        },
        toolbox: {},
        series: [{
          top: '10%',
          data: [
            { value: this.data.counts[0], name: this.data.labels[0] },
            { value: this.data.counts[1], name: this.data.labels[1] }
          ],
          type: 'pie',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            formatter: '{d}%',
            fontSize: 11,
            position: 'inside',
            color: '#f2f2f2'
          }
        }]
      }

      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('rate-data'))
  }
}
</script>

<style lang="scss" scoped>
  #rate-data {
    margin: 0 10px;
    background-color: #fff;
    height: 300px;
    border-radius: 5px;
  }
</style>
