<template>
  <div>
    <my-nav-bar
      title="报表明细"
      left-text="返回"
      left-arrow
    />
    <div class="container">
      <order-data :data="reportData.order_data" style="margin-bottom: 10px" />
      <rate-data :data="reportData.rate_data" style="margin-bottom: 10px" />
      <top-data :data="reportData.top_data" />
      <van-list
        v-model="loading"
        class="list"
        :finished="finished"
        @load="getList"
      >
        <div class="list-title">已购商品</div>
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-cell
          v-for="orderGoods in list"
          :key="orderGoods.goods_id"
          :title="orderGoods.name"
          :value="`${orderGoods.volume}${orderGoods.least_unit}`"
          :label="`最后拿货时间：${orderGoods.last_datetime}`"
          title-class="cell-title"
          center
        />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import OrderData from './components/order-data'
import TopData from './components/top-data'
import RateData from './components/rate-data'
import { getReportDetail, getGoodsRank } from '@/api/client-report'
export default {
  components: { myNavBar, OrderData, TopData, RateData },
  data() {
    return {
      loading: false,
      finished: false,
      reportData: {},
      list: [],
      listQuery: {
        page: 0,
        limit: 10,
        month: this.$route.query.month,
        client_id: this.$route.query.client_id
      },
      showEmpty: false
    }
  },
  created() {
    const params = {
      month: this.$route.query.month,
      client_id: this.$route.query.client_id
    }
    this.beginLoad()
    getReportDetail(params).then(res => {
      this.endLoad()
      this.reportData = res.data
    })
  },
  methods: {
    getList() {
      this.listQuery.page++
      getGoodsRank(this.listQuery).then(res => {
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 56px;
  }
  .list {
    margin: 10px 10px 0 10px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 10px);
    margin-bottom: calc(constant(safe-area-inset-bottom) + 10px);
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  .list-title {
    padding: 10px;
    background-color: #fff;
    font-size: 15px;
    font-weight: bold;
  }
  .cell-title {
    flex: 4;
  }
</style>
