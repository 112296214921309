<template>
  <div id="top-data" />
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    data() {
      const option = {
        title: {
          text: 'TOP10',
          padding: [15, 0, 10, 10],
          textStyle: {
            fontFamily: 'Microsoft YaHei',
            fontSize: 15,
            color: '#464646'
          }
        },
        color: ['#ee6666'],
        legend: {
          data: ['商品数量'],
          top: 10
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        toolbox: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          data: this.data.lables
        },
        yAxis: {},
        series: [{
          name: '商品数量',
          data: this.data.volumes,
          type: 'bar'
        }]
      }

      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('top-data'))
  }
}
</script>

<style lang="scss" scoped>
  #top-data {
    margin: 0 10px;
    background-color: #fff;
    height: 300px;
    border-radius: 5px;
  }
</style>
