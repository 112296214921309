<template>
  <div id="order-data" />
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    data() {
      const option = {
        title: {
          text: '客户订单',
          padding: [15, 0, 10, 10],
          textStyle: {
            fontFamily: 'Microsoft YaHei',
            fontSize: 15,
            color: '#464646'
          }
        },
        legend: {
          data: ['下单金额'],
          top: 10
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        toolbox: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          data: this.data.labels
        },
        yAxis: {},
        series: [{
          name: '下单金额',
          data: this.data.amounts,
          type: 'line',
          smooth: true
        }]
      }

      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('order-data'))
  }
}
</script>

<style lang="scss" scoped>
  #order-data {
    margin: 0 10px;
    background-color: #fff;
    height: 300px;
    border-radius: 5px;
  }
</style>
