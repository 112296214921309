import request from '@/utils/request'

export function getClients(params) {
  return request({
    method: 'get',
    url: 'client-report',
    params
  })
}

export function getReportDetail(params) {
  return request({
    method: 'get',
    url: 'client-report/detail',
    params
  })
}

export function getGoodsRank(params) {
  return request({
    method: 'get',
    url: 'client-report/goods-rank',
    params
  })
}

export function getLines(params) {
  return request({
    method: 'get',
    url: 'client-report/line',
    params
  })
}
