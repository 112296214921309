var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "报表明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "container"
  }, [_c('order-data', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "data": _vm.reportData.order_data
    }
  }), _c('rate-data', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "data": _vm.reportData.rate_data
    }
  }), _c('top-data', {
    attrs: {
      "data": _vm.reportData.top_data
    }
  }), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c('div', {
    staticClass: "list-title"
  }, [_vm._v("已购商品")]), _vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (orderGoods) {
    return _c('van-cell', {
      key: orderGoods.goods_id,
      attrs: {
        "title": orderGoods.name,
        "value": "".concat(orderGoods.volume).concat(orderGoods.least_unit),
        "label": "\u6700\u540E\u62FF\u8D27\u65F6\u95F4\uFF1A".concat(orderGoods.last_datetime),
        "title-class": "cell-title",
        "center": ""
      }
    });
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }